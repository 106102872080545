import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Alert, Grid2, styled } from '@mui/material';
import { usePostMfaSetup, usePostMfaSetupVerify } from '@sentinel/hooks';
import { useAuth } from '@vestwell-frontend/elements';
import {
  Button,
  Form,
  FormatPhoneNumber,
  FormField,
  FormFieldProps,
  FormSaveButton,
  ModalBody,
  ModalFooter,
  Text,
  useWizard
} from '@vestwell-frontend/ui';

import { FC, useCallback } from 'react';
import * as yup from 'yup';

import { MfaSetupState } from '../config/mfaSetup';
import { ResendOtpButton } from './ResendOtpButton';

const StyledFormFieldContainer = styled('div')<
  FormFieldProps & { method: MfaSetupState['method'] }
>(({ method, theme }) => ({
  '& > .MuiFormControl-root': {
    marginBottom: method === 'phone' ? theme.spacing(4) : theme.spacing(8),
    width: theme.spacing(50)
  }
}));

const StyledResendOtpButton = styled(ResendOtpButton)(({ theme }) => ({
  marginBottom: theme.spacing(8)
}));

const schema = yup.object().shape({
  securityCode: yup
    .string()
    .min(6, 'Must be at least 6 digits')
    .required('Required')
});

export const MfaSetupVerifyCode: FC = () => {
  const { setToken } = useAuth();

  const ctx = useWizard<MfaSetupState>();

  const postMfaSetup = usePostMfaSetup({
    mutation: {
      onSuccess: res => {
        console.log(res.code);
      }
    }
  });

  const postMfaSetupVerify = usePostMfaSetupVerify({
    mutation: {
      onSuccess: data => {
        ctx.setState({
          methodVerified: true
        });
        setToken(data.token);
      }
    }
  });

  const onBack = useCallback(() => {
    ctx.setState({
      methodSelected: false
    });
  }, []);

  const onResendCode = useCallback(async () => {
    await postMfaSetup.mutateAsync({
      data: {
        type: ctx.state.method,
        value: ctx.state.value
      }
    });
  }, [ctx.state]);

  const onSubmit = useCallback(
    async values => {
      await postMfaSetupVerify.mutateAsync({
        data: {
          code: values.securityCode,
          subType: ctx.state.subType
        }
      });
    },
    [ctx.state.subType]
  );

  const isDisabled = postMfaSetupVerify.error?.message?.startsWith(
    'Your account has been temporarily locked'
  );

  return (
    <Form
      className='contents'
      onChange={postMfaSetupVerify.reset}
      onSubmit={onSubmit}
      validateOnMount={false}
      validationSchema={schema}>
      <ModalBody>
        <Grid2 alignItems='center' container direction='column' spacing={4}>
          <Grid2
            alignItems='center'
            display='flex'
            flexDirection='column'
            size={{
              lg: 12
            }}>
            <Text align='center' mb={0}>
              Enter the 6-digit code{' '}
              {ctx.state.subType === 'text'
                ? 'we have sent to '
                : ctx.state.subType === 'voice'
                  ? 'provided on the call to '
                  : 'generated by your authentication app'}
              {ctx.state.method === 'phone' && (
                <>
                  <FormatPhoneNumber mask value={ctx.state.value} />.
                </>
              )}
            </Text>
          </Grid2>
          <Grid2
            alignItems='center'
            display='flex'
            flexDirection='column'
            flexGrow={1}
            justifyContent='center'
            size={{
              lg: 12
            }}>
            <StyledFormFieldContainer method={ctx.state.method}>
              <FormField
                align='center'
                autoComplete='one-time-code'
                autoFocus
                disabled={isDisabled}
                format='numericString'
                label='Security Code'
                maxLength={6}
                name='securityCode'
                type='tel'
                variant='large'
              />
            </StyledFormFieldContainer>
            {ctx.state.method === 'phone' && (
              <StyledResendOtpButton
                disabled={isDisabled}
                inputName={`${ctx.state.method}Verification`}
                onClick={onResendCode}
                variant='inline'
              />
            )}
          </Grid2>
          {(postMfaSetup.error || postMfaSetupVerify.error) && (
            <Grid2>
              <Alert
                classes={{ root: 'flex-shrink inline-flex' }}
                severity='error'
                variant='standard'>
                {postMfaSetup.error?.message ??
                  postMfaSetupVerify.error?.message}
              </Alert>
            </Grid2>
          )}
        </Grid2>
      </ModalBody>
      <ModalFooter>
        <Button disabled={isDisabled} onClick={onBack} variant='text'>
          Back
        </Button>
        <FormSaveButton
          disabled={isDisabled}
          endIcon={<KeyboardArrowRightOutlined fontSize='small' />}>
          Confirm
        </FormSaveButton>
      </ModalFooter>
    </Form>
  );
};

MfaSetupVerifyCode.displayName = 'MfaSetupVerifyCode';
