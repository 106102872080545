import { InfoOutlined } from '@mui/icons-material';
import { FormControl, FormHelperText, Stack, styled } from '@mui/material';
import { useCamelCase } from '@vestwell-frontend/hooks';

import { FieldValidator, useField } from 'formik';
import { FC, ReactNode, useCallback, useContext, useId } from 'react';

import { TextBox, TextBoxProps } from '../TextBox';
import { IsRequiredFieldContext } from './Form';
import { FormFieldLabel } from './FormFieldLabel';

export type FormFieldProps = TextBoxProps & {
  details?: string;
  disabled?: boolean;
  help?: string | ReactNode;
  helpModal?: ReactNode;
  hideError?: boolean;
  hideLabel?: boolean;
  hideLocked?: boolean;
  label?: string;
  revealable?: boolean;
  validate?: FieldValidator;
};

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex'
}));

const StyledFormHelperText = styled(FormHelperText)(() => ({
  marginLeft: 0,
  marginRight: 0
}));

export const FormField: FC<FormFieldProps> = ({
  align,
  'aria-describedby': ariaDescribedBy,
  'aria-labelledby': ariaLabelledBy,
  details,
  disabled,
  endAdornment,
  fullWidth,
  helpModal,
  hideLabel,
  hideLocked,
  hideError,
  help,
  label,
  readOnly,
  width,
  ...props
}) => {
  const isRequired = useContext(IsRequiredFieldContext)[props.name] ?? false;

  const testId = useCamelCase(props.name);

  const [field, meta, helpers] = useField({
    name: props.name,
    validate: props.validate
  });

  const detailsId = useId();
  const disabledId = useId();
  const helpModalId = useId();
  const errorId = useId();
  const infoId = useId();
  const labelId = useId();

  const errorMessage = meta.touched ? meta.error : undefined;

  const describedBy =
    `${ariaDescribedBy ? `${ariaDescribedBy} ` : ''}${
      disabled ? `${disabledId} ` : ''
    }${help ? `${infoId} ` : ''}${helpModal ? `${helpModalId} ` : ''}${
      details ? `${detailsId} ` : ''
    }${errorMessage ? `${errorId} ` : ''}`.trimEnd() || undefined;

  const onChange = useCallback(
    value => {
      helpers.setValue(value);

      if (props.onChange) {
        props.onChange(value);
      }
    },
    [helpers.setValue, props.onChange]
  );

  return (
    <StyledFormControl
      data-component='textField'
      data-testid={testId}
      error={!!meta.error && !!meta.touched}
      required={!!isRequired}>
      <FormFieldLabel
        data-component='textFieldLabel'
        details={details}
        detailsId={detailsId}
        disabled={disabled}
        disabledId={disabledId}
        helpModal={helpModal}
        helpModalId={helpModalId}
        hideLabel={hideLabel}
        hideLocked={hideLocked}
        info={help}
        infoId={infoId}
        label={label}
        labelId={labelId}
        name={props.name}
        required={!!isRequired}
      />
      <TextBox
        {...props}
        align={align}
        aria-describedby={describedBy}
        aria-invalid={!!meta.error && !!meta.touched}
        aria-labelledby={`${
          ariaLabelledBy ? `${ariaLabelledBy} ` : ''
        }${labelId}`}
        disabled={disabled}
        endAdornment={endAdornment}
        fullWidth={fullWidth}
        id={props.id || props.name}
        label={label}
        onBlur={props.onBlur || field.onBlur}
        onChange={onChange}
        readOnly={readOnly}
        required={isRequired}
        value={field.value ?? ''}
        width={width}
      />
      <StyledFormHelperText
        aria-atomic='true'
        data-component='textFieldError'
        data-testid={testId}
        id={errorId}
        role='alert'>
        {!hideError && errorMessage ? (
          <Stack alignItems='center' direction='row' gap={1}>
            <InfoOutlined color='error' fontSize='small' />
            {!!label && <span className='sr-only'>Error for {label}:</span>}
            {errorMessage}
          </Stack>
        ) : (
          ' '
        )}
      </StyledFormHelperText>
    </StyledFormControl>
  );
};

FormField.displayName = 'FormField';
