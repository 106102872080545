import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { IconButton, Input, InputProps, styled } from '@mui/material';
import { useCamelCase, useToggle } from '@vestwell-frontend/hooks';

import { format, parse, parseISO } from 'date-fns';
import { pick } from 'lodash';
import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  NumberFormatBase,
  NumberFormatBaseProps,
  NumericFormat
} from 'react-number-format';
import { useUpdateEffect } from 'react-use';

const isNumberAllowed =
  ({ min, max }) =>
  input => {
    const isValueDefined = typeof input.floatValue === 'number';
    const isMinDefined = typeof min === 'number';
    const isMaxDefined = typeof max === 'number';

    const isAboveMin =
      !isMinDefined ||
      !isValueDefined ||
      (isMinDefined && isValueDefined && input.floatValue >= min);

    const isBelowMax =
      !isMaxDefined ||
      !isValueDefined ||
      (isMaxDefined && isValueDefined && input.floatValue <= max);

    return isAboveMin && isBelowMax ? input : false;
  };

export type TextBoxFormatProps = Omit<
  NumberFormatBaseProps,
  'onChange' | 'value' | 'format'
> & {
  dateMask?: 'MM/dd/yyyy' | 'MM/yyyy';
  dateInputFormat?: 'yyyy-MM-dd' | 'MM/dd/yyyy' | 'MM/yyyy';
  format?:
    | 'currencyUs'
    | 'date'
    | 'dateShort'
    | 'ein'
    | 'number'
    | 'numericString'
    | 'phoneUs'
    | 'percent'
    | 'routing'
    | 'ssn'
    | 'zip';
  precision?: number;
  onChange?(value): void;
  suffix?: string;
  value?: string;
};

type MaskOptions = NumberFormatBaseProps & {
  allowEmptyFormatting?: boolean;
  expectFormattedValue?: boolean;
  expectNumericValue?: boolean;
  formatChangeValue?(value: string): string;
  formatPasteValue?(value: string): string;
  formatDisplayValue?(value: string): string;
  reformatOnBlur?(value: string): string;
};

type Masks = Record<
  TextBoxFormatProps['format'],
  (options: {
    dateMask?: string;
    dateInputFormat?: string;
    max?: string | number | Date;
    min?: string | number | Date;
    precision?: number;
    suffix?: string;
  }) => MaskOptions
>;

export const maskProps: Masks = {
  currencyUs: ({ min, max, precision }) => ({
    allowEmptyFormatting: false,
    allowLeadingZeros: false,
    allowNegative: ((min || 0) as number) < 0,
    decimalScale: precision || 0,
    expectNumericValue: true,
    isAllowed: isNumberAllowed({
      max,
      min: min || 0
    }),
    prefix: '$',
    thousandSeparator: true
  }),
  date: ({ dateMask, dateInputFormat }) => ({
    allowEmptyFormatting: false,
    expectFormattedValue: true,
    format: value => {
      return value
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
        .replace(/^(\d{2})\/(\d{2})(.+)/, '$1/$2/$3')
        .substring(0, 10);
    },
    formatChangeValue: value => {
      try {
        return value.length < dateInputFormat.length
          ? value
          : format(new Date(value), dateInputFormat);
      } catch (e) {
        return value.length === dateInputFormat.length ? value : '';
      }
    },
    formatDisplayValue: value => {
      try {
        return format(
          parse(value, dateInputFormat, new Date()) as any,
          dateMask
        );
      } catch (e) {
        return '';
      }
    },
    formatPasteValue: value => {
      try {
        return format(
          parseISO(new Date(value).toISOString().replace('Z', '')),
          dateMask
        );
      } catch (e) {
        return value;
      }
    },
    mask: '',
    placeholder: dateMask.toUpperCase()
  }),
  dateShort: () => ({
    allowEmptyFormatting: false,
    expectFormattedValue: true,
    format: value => {
      return value
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
        .substring(0, 5);
    }
  }),
  ein: () => ({
    allowEmptyFormatting: false,
    expectFormattedValue: true,
    format: value => {
      return value
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d{1,7})/, '$1-$2')
        .substring(0, 10);
    },
    mask: '',
    placeholder: '##-#######'
  }),
  number: ({ min, max, precision }) => ({
    allowEmptyFormatting: false,
    allowLeadingZeros: false,
    allowNegative: ((min || 0) as number) < 0,
    decimalScale: precision || 0,
    expectNumericValue: true,
    isAllowed: isNumberAllowed({
      max,
      min: min || 0
    }),
    thousandSeparator: true
  }),
  numericString: ({ min, max }) => ({
    allowEmptyFormatting: false,
    allowLeadingZeros: true,
    allowNegative: ((min || 0) as number) < 0,
    decimalScale: 0,
    expectNumericValue: false,
    isAllowed: isNumberAllowed({
      max,
      min: min || 0
    }),
    thousandSeparator: false
  }),
  percent: ({ min, max, precision, suffix }) => ({
    allowEmptyFormatting: false,
    allowLeadingZeros: false,
    allowNegative: ((min || 0) as number) < 0,
    decimalScale: precision || 0,
    expectNumericValue: true,
    isAllowed: isNumberAllowed({
      max: max || 100,
      min: min || 0
    }),
    suffix: suffix || '%'
  }),
  phoneUs: () => ({
    allowEmptyFormatting: false,
    expectFormattedValue: true,
    format: value => {
      return value
        .replace(/\D/g, '')
        .replace(/^(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/^(\d{3})-(\d{3})(.+)/, '$1-$2-$3')
        .substring(0, 12);
    },
    mask: '',
    placeholder: '###-###-####'
  }),
  routing: () => ({
    allowEmptyFormatting: false,
    expectFormattedValue: true,
    format: value => {
      return value.replace(/\D/g, '').substring(0, 9);
    },
    mask: '',
    placeholder: '#########'
  }),
  ssn: () => ({
    allowEmptyFormatting: false,
    expectFormattedValue: true,
    format: value => {
      return value
        .replace(/\D/g, '')
        .replace(/^(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/^(\d{3})-(\d{2})(.+)/, '$1-$2-$3')
        .substring(0, 11);
    },
    mask: '',
    placeholder: '###-##-####'
  }),
  zip: () => ({
    allowEmptyFormatting: false,
    expectFormattedValue: true,
    format: value => {
      return (value || '').length > 5
        ? value.substring(0, 5) + '-' + value.substring(5)
        : value;
    },
    isAllowed: input => {
      if (
        !(
          typeof input.formattedValue === 'string' &&
          input.formattedValue !== ''
        )
      ) {
        return true;
      }

      return (
        input.formattedValue.length >= 0 && input.formattedValue.length <= 10
      );
    },
    placeholder: '#####-####',
    reformatOnBlur: value => {
      if (value === '' || value === undefined) {
        return '';
      }

      if (value?.length >= 5 && value?.length < 10) {
        return value.substring(0, 5);
      }

      if (value?.length >= 0 && value?.length > 10) {
        return value.substring(0, 10);
      }

      return value;
    }
  })
};

export const TextBoxFormat = forwardRef<HTMLInputElement, TextBoxFormatProps>(
  (
    {
      dateMask = 'MM/dd/yyyy',
      dateInputFormat = 'yyyy-MM-dd',
      format,
      onChange,
      min,
      max,
      precision,
      placeholder,
      ...props
    },
    ref
  ) => {
    const {
      allowEmptyFormatting,
      expectFormattedValue,
      expectNumericValue,
      formatChangeValue,
      formatDisplayValue,
      formatPasteValue,
      reformatOnBlur,
      ...inputProps
    } = useMemo<MaskOptions>(
      () =>
        maskProps[format]({
          dateInputFormat,
          dateMask,
          max,
          min,
          precision,
          suffix: props.suffix
        }),
      [dateInputFormat, dateMask, format, max, min, precision, props.suffix]
    );

    const isDate = format === 'date';

    const [isFocused, toggleIsFocused] = useToggle(false);

    const [textValue, setTextValue] = useState(() =>
      formatDisplayValue
        ? formatDisplayValue(props.value?.toString())
        : [null, undefined, ''].includes(props.value?.toString())
          ? ''
          : props.value
    );

    const onBlur = useCallback(
      e => {
        if (reformatOnBlur) {
          setTextValue(() => reformatOnBlur(e.target.value));
        }

        toggleIsFocused();

        if (props.onBlur) {
          props.onBlur(e);
        }
      },
      [props.onBlur, reformatOnBlur]
    );

    const onFocus = useCallback(
      e => {
        toggleIsFocused();

        if (props.onFocus) {
          props.onFocus(e);
        }
      },
      [props.onFocus]
    );

    const onPaste = useCallback(
      e => {
        e.stopPropagation();
        /** prevent duplication of value */
        e.preventDefault();
        setTextValue(formatPasteValue(e.clipboardData.getData('text')));
      },
      [formatPasteValue]
    );

    /** update the textValue when changes occur */
    const onValueChange = useCallback(
      event => {
        setTextValue(
          () =>
            event[
              expectFormattedValue
                ? 'formattedValue'
                : expectNumericValue
                  ? 'floatValue'
                  : 'value'
            ]
        );
      },
      [expectFormattedValue, expectNumericValue]
    );

    const Component = useMemo(
      () =>
        ['currencyUs', 'number', 'numericString', 'percent'].includes(format)
          ? NumericFormat
          : NumberFormatBase,
      [format]
    );

    useUpdateEffect(() => {
      onChange(
        expectNumericValue && textValue === ''
          ? null
          : formatChangeValue
            ? formatChangeValue(textValue)
            : textValue
      );
    }, [textValue]);

    /** update textValue whenever props.value is changed (external) */
    useUpdateEffect(() => {
      if (!isFocused) {
        const value = [null, undefined, ''].includes(props.value)
          ? ''
          : props.value;

        setTextValue(() =>
          formatDisplayValue ? formatDisplayValue(value) : value
        );
      }
    }, [props.value, isDate, dateMask]);

    return (
      <Component
        data-raw-value={props.value}
        {...props}
        {...inputProps}
        getInputRef={ref}
        onBlur={onBlur}
        onFocus={onFocus}
        onPaste={formatPasteValue ? onPaste : undefined}
        onValueChange={onValueChange}
        placeholder={placeholder || inputProps.placeholder}
        value={textValue}
        valueIsNumericString={
          typeof props.value === 'string' && /^\d*\.?\d*$/.test(textValue)
        }
      />
    );
  }
);

TextBoxFormat.displayName = 'TextBoxFormat';

export type TextBoxProps = Omit<TextBoxFormatProps, 'format'> & {
  align?: 'center' | 'left' | 'right';
  endAdornment?: ReactNode;
  format?: TextBoxFormatProps['format'] | 'email';
  label?: ReactNode;
  noFocusStyle?: boolean;
  revealable?: boolean;
  variant?: 'small' | 'large';
  width?: number;
  fullWidth?: boolean;
};

const StyledInput = styled(Input, {
  shouldForwardProp: prop => prop !== 'align'
})<InputProps & Pick<TextBoxProps, 'align' | 'variant'>>(props => ({
  '& input': {
    ...(props['data-secure'] && {
      WebkitTextSecurity: 'disc'
    })
  },
  ...(props.variant === 'large' && {
    fontSize: props.theme.spacing(10)
  }),
  textAlign: props.align ? props.align : undefined
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  padding: 0
}));

export const TextBox = forwardRef<HTMLInputElement, TextBoxProps>(
  (
    {
      align,
      autoComplete = 'off',
      autoFocus = false,
      disabled,
      endAdornment,
      fullWidth,
      label,
      noFocusStyle = false,
      readOnly,
      required,
      revealable = false,
      type = 'text',
      variant = 'small',
      width,
      ...props
    },
    ref
  ) => {
    const testId = useCamelCase(props.name);

    const [reveal, toggleReveal] = useToggle(false);

    const $input = useRef(null);

    useImperativeHandle(ref, () => $input.current);

    useEffect(() => {
      const timeout = setTimeout(() => {
        if (autoFocus) {
          $input.current.focus();
          $input.current.select();
        }
      }, 10);

      return () => {
        clearTimeout(timeout);
      };
    }, []);

    const onPaste = useCallback(
      e => {
        if (props.onPaste) {
          return props.onPaste(e);
        }

        e.stopPropagation();
        e.preventDefault();

        props.onChange(e.clipboardData.getData('text').trim());
      },
      [props.onChange, props.onPaste]
    );

    const isMask = props.format && props.format !== 'email';

    return (
      <StyledInput
        {...pick(props, 'className')}
        align={align}
        data-no-focus-style={noFocusStyle}
        data-secure={!!revealable && type !== 'password' && !reveal}
        disabled={!!disabled}
        endAdornment={
          revealable ? (
            <StyledIconButton
              aria-label={`${reveal ? 'Hide' : 'Show'}${
                label || props.name ? ` ${label || props.name}` : ''
              }`}
              aria-pressed={reveal}
              data-component='textFieldRevealButton'
              data-testid={testId}
              onClick={toggleReveal}
              onMouseDown={toggleReveal}>
              {reveal ? (
                <VisibilityOffOutlined fontSize='small' />
              ) : (
                <VisibilityOutlined fontSize='small' />
              )}
            </StyledIconButton>
          ) : (
            endAdornment
          )
        }
        fullWidth={fullWidth}
        inputComponent={isMask ? (TextBoxFormat as any) : undefined}
        inputProps={{
          ...props,
          'aria-disabled': !!disabled,
          'aria-live': 'polite',
          'aria-required': !!required,
          autoComplete:
            props.name === 'password'
              ? reveal
                ? 'off'
                : autoComplete
              : autoComplete,
          autoFocus,
          'data-component': 'textFieldInput',
          'data-testid': testId,
          onChange: isMask
            ? props.onChange
            : //@ts-expect-error
              e => props.onChange(e.target.value),
          onPaste: props.format === 'email' ? onPaste : props.onPaste,
          type:
            revealable && type === 'password'
              ? reveal
                ? 'text'
                : 'password'
              : type
        }}
        inputRef={$input}
        readOnly={readOnly}
        required={!!required}
        sx={theme => ({
          width: width ? theme.spacing(width) : undefined
        })}
        value={props.value ?? ''}
        variant={variant}
      />
    );
  }
);

TextBox.displayName = 'TextBox';
